import React from "react";
import styled from "styled-components";
import acmeBankLogo from "./acme_bank_logo.svg";

const StyledBox = styled.div`
  width: 100%;
  max-width: 768px;
  height: fit-content;
  min-height: 650px;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 80px 1fr;
  border-radius: 8px;
  box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.15);
  margin: 60px auto 30px auto;
`;

const StyledBoxHeader = styled.div`
  width: 100%;
  height: fit-content;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 25px 0;
  background-color: #f2f2f2;
`;

const StyledBoxContent = styled.div`
  width: 100%;
  height: 100%;
  max-width: 630px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 12px;
  margin: 0 auto;
  padding: 0 20px 30px 20px;
`;

function Box({ children, ...restProps }) {
  return (
    <StyledBox {...restProps}>
      <StyledBoxHeader>
        <img src={acmeBankLogo} alt="acme bank" />
      </StyledBoxHeader>
      <StyledBoxContent>{children}</StyledBoxContent>
    </StyledBox>
  );
}

export default Box;
