export const FINC_FORM_KEY_STORAGE = "fincKeyStorage";

export const FINC_FORM_STEPS = Object.freeze({
  start: null, // StartScreen
  welcome: "welcome", // WelcomeScreen
  webform: "webform",
  issuance: 'issuance',
  kyc: "kyc",
  tax: "tax",
  terms: "terms",
  funds: "funds",
});

export const FINC_FORM_PROGRESS = Object.freeze({
  [FINC_FORM_STEPS.start]: 0,
  [FINC_FORM_STEPS.welcome]: 25,
  [FINC_FORM_STEPS.webform]: 25,
  [FINC_FORM_STEPS.issuance]: 25,
  [FINC_FORM_STEPS.kyc]: 25,
  [FINC_FORM_STEPS.tax]: 50,
  [FINC_FORM_STEPS.terms]: 75,
  [FINC_FORM_STEPS.funds]: 100,
});
