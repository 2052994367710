import React from "react";
import styled from "styled-components";

const StyledSubtitle = styled.span`
  width: 100%;
  display: block;
  font-size: 14px;
  line-height: 24px;
  margin-bottom: 50px;
  text-align: center;
  color: #4f4f4f;
`;

function Subtitle({ children, ...restProps }) {
  return <StyledSubtitle {...restProps}>{children}</StyledSubtitle>;
}

export default Subtitle;
