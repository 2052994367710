import React from "react";
import styled from "styled-components";

const StyledTextRowIconWrapper = styled.div`
  width: 100%;
  max-width: 440px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 24px;
  margin: 0 auto 12px auto;
`;

const StyledTextRowIcon = styled.div`
  width: 32px;
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const StyledTextRowIconLabel = styled.span`
  font-size: 18px;
  line-height: 26px;
  text-align: left;
`;

function TextRowIcon({ text = "", icon }) {
  return (
    <StyledTextRowIconWrapper>
      <StyledTextRowIcon>
        <img src={icon} alt="row icon" />
      </StyledTextRowIcon>
      <StyledTextRowIconLabel>{text}</StyledTextRowIconLabel>
    </StyledTextRowIconWrapper>
  );
}

export default TextRowIcon;
