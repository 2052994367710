import React from "react";
import styled from "styled-components";

const StyledButton = styled.button`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  width: fit-content;
  min-width: 194px;
  font-size: 12px;
  line-height: 16px;
  background-color: #006aac;
  color: #ffffff;
  border: solid 1px transparent;
  border-radius: 4px;
  padding: 8px;

  &:hover {
    border: solid 1px #006aac;
    color: #006aac;
    background-color: transparent;
  }
`;

function Button({ children, ...restProps }) {
  return <StyledButton {...restProps}>{children}</StyledButton>;
}

export default Button;
