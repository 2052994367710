import { Suspense, lazy, useEffect, useState } from "react";

import Layout from "library/Layout";
import Box from "library/Box";
import ProgressBar from "library/ProgressBar";
import FinclusiveHelp from "library/FinclusiveHelp";
import StartScreen from "screens/StartScreen";
import { FINC_FORM_KEY_STORAGE, FINC_FORM_PROGRESS, FINC_FORM_STEPS } from "shared/constants";
import { checkCurrentKeyStep } from "shared/helpers";
import { ENDPOINTS, apiRequest } from "shared/api";

const WelcomeScreen = lazy(() => import("screens/WelcomeScreen"));
const WebformScreen = lazy(() => import("screens/WebformScreen"));
const KycScreen = lazy(() => import("screens/KycScreen"));
const TaxScreen = lazy(() => import("screens/TaxScreen"));
const TermsScreen = lazy(() => import("screens/TermsScreen"));
const FundsScreen = lazy(() => import("screens/FundsScreen"));
const IssuanceScreen = lazy(() => import("screens/IssuanceScreen"));

const validateAccessCode = async (code) => {
  const result = await fetch(
    process.env.REACT_APP_ENDPOINT_BASE + ENDPOINTS.validation,
      {
        method: 'get',
        headers: {
          'x-finclusive-apikey': code,
        },
      }
  );

  if(result.status !== 200) {
    return Promise.reject("Access Code missing or invalid");
  }

  sessionStorage.setItem('accessCode', code);
}


function App() {
  const [fincKeyStep, setFinkKeyStep] = useState(checkCurrentKeyStep());
  const [isLoading, setLoading] = useState(true);
  const [metadata, setMetadata] = useState({
    clientFincId: sessionStorage.getItem('clientFincId'),
  });
  const progressValue = FINC_FORM_PROGRESS[fincKeyStep];

  const params = new URLSearchParams(document.location.search);
  let code = params.get("code");

  const handleStepUpdate = (newStep, newMeta = {}) => {
    setFinkKeyStep(newStep);
    sessionStorage.setItem(FINC_FORM_KEY_STORAGE, newStep);
    setMetadata(s => ({...s, ...newMeta}));
  };

  useEffect(() => {
    validateAccessCode(code).then(() => {
      setLoading(false);
      apiRequest.defaults.headers.common['apikey'] = code;
    }).catch((er) => console.error(er));

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const rederActiveScreenStep = () => {
    switch (fincKeyStep) {
      case FINC_FORM_STEPS.welcome:
        return <WelcomeScreen handleStepUpdate={handleStepUpdate} />;
      case FINC_FORM_STEPS.webform:
        return <WebformScreen handleStepUpdate={handleStepUpdate} />;
      case FINC_FORM_STEPS.issuance:
        return <IssuanceScreen metadata={metadata} handleStepUpdate={handleStepUpdate} />;
      case FINC_FORM_STEPS.kyc:
        return <KycScreen handleStepUpdate={handleStepUpdate} />;
      case FINC_FORM_STEPS.tax:
        return <TaxScreen handleStepUpdate={handleStepUpdate} />;
      case FINC_FORM_STEPS.terms:
        return <TermsScreen handleStepUpdate={handleStepUpdate} />;
      case FINC_FORM_STEPS.funds:
        return <FundsScreen handleStepUpdate={handleStepUpdate} />;
      default:
        return <StartScreen handleStepUpdate={handleStepUpdate} />;
    }
  };

  if(isLoading) return <p> Verifying request, please wait... </p>;

  return (
    <Layout>
      <Box>
        <ProgressBar isVisible={fincKeyStep} value={progressValue} />
        <Suspense fallback={<span>Loading...</span>}>{rederActiveScreenStep()}</Suspense>
      </Box>
      <FinclusiveHelp />
      <div id="google_translate_element"></div>
    </Layout>
  );
}

export default App;
