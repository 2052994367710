import { FINC_FORM_KEY_STORAGE, FINC_FORM_STEPS } from "./constants";

export const checkCurrentKeyStep = () => {
  const key = sessionStorage.getItem(FINC_FORM_KEY_STORAGE);
  const validKeys = Object.values(FINC_FORM_STEPS);

  if (!key || !validKeys.includes(key)) return null;

  return key;
};
