import axios from "axios";

export const apiRequest = axios.create({
  baseURL: process.env.REACT_APP_ENDPOINT_BASE,
  timeout: 60000,
});

export const ENDPOINTS = Object.freeze({
  initiateVerify: "/compliance/vc/gendigital/initiate-verify",
  status: "/compliance/vc/gendigital/verify",
  validation: "/customer/iframe/customer/document-validation",
  webform: "/customer/iframe/client/individual/form",
  issuance: (customerFincId, clientFincId) => `/compliance/vc/gendigital/${customerFincId}/${clientFincId}/initiate-issuance`,
});
