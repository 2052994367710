import React from "react";
import styled from "styled-components";

const StyledFinclusiveHelp = styled.div`
  display: flex;
  justify-content: center;
  gap: 8px;
  width: 100%;
  max-width: 768px;
  font-size: 12px;
  color: #4f4f4f;
  text-align: center;
  margin-bottom: 30px;

  a {
    text-decoration: none;
  }
`;

function FinclusiveHelp() {
  return (
    <StyledFinclusiveHelp>
      If you have any question, please contact FinClusive at
      <a href="mailto:support@finclusive.com">support@finclusive.com</a>
    </StyledFinclusiveHelp>
  );
}

export default FinclusiveHelp;
