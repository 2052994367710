import React from "react";
import styled from "styled-components";

const StyledProgressBarWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 20px 0;
`;

const StyledProgressBar = styled.progress`
  width: 100%;
`;

const StyledProgressBarList = styled.ul`
  list-style: none;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const StyledProgressBarListItem = styled.li`
  font-size: 12px;
  width: 100%;
  text-align: center;
  font-size: 12px;
  line-height: 14px;

  &:first-child {
    text-align: left;
  }

  &:last-child {
    text-align: right;
  }
`;

const StyledSpace = styled.div`
  width: 100%;
  height: 78px;
`;

function ProgressBar({ value = "0", isVisible = false }) {
  if (!isVisible) {
    return <StyledSpace />;
  }

  return (
    <StyledProgressBarWrapper>
      <StyledProgressBar value={value} max="100"></StyledProgressBar>
      <StyledProgressBarList>
        <StyledProgressBarListItem>KYC Credentials</StyledProgressBarListItem>
        <StyledProgressBarListItem>Tax Certification</StyledProgressBarListItem>
        <StyledProgressBarListItem>Add Funds</StyledProgressBarListItem>
      </StyledProgressBarList>
    </StyledProgressBarWrapper>
  );
}

export default ProgressBar;
