import React from "react";
import styled from "styled-components";

const StyledTitle = styled.h1`
  font-size: 26px;
  line-height: 30px;
  font-weight: 300;
  text-align: center;
  margin: 0 0 12px 0;
`;

function Title({ children, ...restProps }) {
  return <StyledTitle {...restProps}>{children}</StyledTitle>;
}

export default Title;
