import React from "react";
import styled from "styled-components";

import Button from "library/Button";
import TextRowIcon from "library/TextRowIcon";
import Title from "library/Title";
import Subtitle from "library/Subtitle";

import money from "./money.svg";
import taxes from "./taxes.svg";
import personal_profile from "./personal_profile.svg";

import { FINC_FORM_STEPS } from "shared/constants";

const StyledStartScreen = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  button {
    margin-top: auto;
  }
`;

const StyledStartScreenRowWrap = styled.div`
  width: 100%;
  margin: 30px 0 80px 0;
`;

function StartScreen({ handleStepUpdate }) {
  return (
    <StyledStartScreen>
      <Title>Let’s get started</Title>
      <Subtitle>Discover the Future of Banking at Your Fingertips</Subtitle>
      <StyledStartScreenRowWrap>
        <TextRowIcon text="You will need to share your KYC credentials" icon={personal_profile} />
        <TextRowIcon text="We will ask you about your tax certification" icon={taxes} />
        <TextRowIcon text="You’ll have the option to add money to your account right away" icon={money} />
      </StyledStartScreenRowWrap>
       <Button onClick={() => handleStepUpdate(FINC_FORM_STEPS.welcome)} type="button">
          Continue
        </Button>
    </StyledStartScreen>
  );
}

export default StartScreen;
